.g-dotted{
    border: 0.1rem dotted black;
    padding: 0.5rem;
}
.g-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.g-column{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    // flex-wrap: wrap;
}
.g-row{
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
}
.g-back{
    width: 2rem;
    padding: 0.5rem;
    cursor: pointer;
}
.g-help{
    cursor: help;
}