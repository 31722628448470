.background{
    background: rgb(255,255,255);
    background: linear-gradient(155deg, rgba(255,255,255,1) 9%, rgba(199,199,199,1) 42%);
}
.background2{
    background: red;
    min-height: 92vh;
}
.searchbar{
    $placeholder-color:rgb(96, 70, 187);

    background-color: white;
    padding: 1rem;
    border-radius: 
        0rem
        1rem
        0rem
        1rem;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $placeholder-color;
        opacity: 1; /* Firefox */
        }
        
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $placeholder-color;
    }
    
    &::-ms-input-placeholder { /* Microsoft Edge */
    color: $placeholder-color;
    }
}
.header_item{
    padding: 0.5rem;
    width: 20vw;
    background: rgb(209, 173, 106);
    color:white;
    justify-content: center;
    font-size: 1.5rem;
}
.case_item {
    background-color: green;
    padding: 0.5rem;
    width: 20vw;
    border-radius: 1rem;
}

