
$base-color: rgb(96, 70, 187);
$end-color: rgb(209, 173, 106);
.Nav-Container {
    background: $base-color;
    background: linear-gradient(67deg, $base-color 21%, $end-color 31%);
    height: 3rem;
    display: flex;
    justify-content:flex-end;
}
.left-start {
    margin-right:auto;
    display: flex;
    gap: 1rem;
}
.right-start{
    display: flex; 
    gap: 2rem;
    padding-right: 2rem;
}
.NavItem {
    display: flex;
    color: rgb(0, 0, 0, 0.75);
    gap: 1rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
        color:rgb(0, 0, 0);
        text-shadow: 
            // -1px -1px 0 rgb(255, 255, 255), 
            1px 1px 5px rgb(255, 255, 255), 
            // -1px 1px 0 rgb(255, 255, 255), 
            // 1px 1px 0 rgb(255, 255, 255), 
    }
}

.daBurger{
    position: fixed;
    padding-top: 0.5rem;
    right:1rem;
    cursor: pointer;
}
.CollapsUL{
    position:absolute;
    top:2rem;
    right:0rem;
    background: linear-gradient(155deg, $end-color 35%, $base-color 89%);
    list-style: none;
    border-radius: 0rem 0rem 0rem 1rem;
}
.CollapsUL li {
    color: rgb(0, 0, 0, 0.75);
    font-weight: bold;
    padding: 0.3rem;
    padding-right: 2.7rem;
    cursor: pointer;
    &:hover{
        color:black;
        text-shadow: 
            // -1px -1px 0 rgb(255, 255, 255), 
            1px 1px 5px rgb(255, 255, 255), 
            // -1px 1px 0 rgb(255, 255, 255), 
            // 1px 1px 0 rgb(255, 255, 255), 

    }
}
