.background {
    min-height: 100vh;
    background: rgb(3,58,91);
    background: radial-gradient(circle, rgba(3,58,91,1) 3%, rgba(160,185,200,1) 73%, rgba(221,209,194,1) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    height: 400px;
    width: 340px;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding-bottom: 3rem;
    background: rgb(255, 255, 255, .35);
    backdrop-filter: blur(5px);
}
.container > * {
    width: 65%;
}
.card {
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    color: white;
    font-size: 1.5rem;
}

.error{
    background: rgba(190, 50, 85);
    @media (max-width: 900px) {
        position: absolute;
    }
    @media (min-width: 901px) {
        position: absolute;
        right: 10vw;
        top: 30vh;
    }
    &:hover {
        cursor: help;
    }
}
$fade-time: 0.5s;
.fade-in {
    animation: fadeIn linear $fade-time;
    -webkit-animation: fadeIn linear $fade-time;
    -moz-animation: fadeIn linear $fade-time;
    -o-animation: fadeIn linear $fade-time;
    -ms-animation: fadeIn linear $fade-time;
  }
  
  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
  
  @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
  }
