html, body {
    margin: 0;
    padding: 0;
    height:100%;
    }

/* width */
::-webkit-scrollbar {
    width: 20px;
}
  
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(65, 61, 64); 
    border-radius: 10px;
}

/* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
    background: #000000; 
}